import { Injectable } from '@angular/core';

import { AlertService } from '@services/alert.service';
import { UtilitiesService } from '@services/utilities.service';

import { UserState } from '@state/user.state';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;

  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string,
  }>;

  prompt(): Promise<void>;
}

@Injectable({
  providedIn: 'root',
})
export class A2hsService {
  public isInstalled = false;

  private deferredPrompt: Event | BeforeInstallPromptEvent | undefined;
  private readyToInstall = false;
  private manuallyInstalled = false;

  constructor(
    private alert: AlertService,
    private userState: UserState,
    private util: UtilitiesService,
  ) {
    this.checkIsInstalled();
  }

  public listenForInstallPrompt(): void {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.readyToInstall = true;
    }, { once: true });

    window.addEventListener('appinstalled', () => {
      this.isInstalled = true;
      this.manuallyInstalled = true;
    }, { once: true });
  }

  public async showInstallPrompt(): Promise<void> {
    if (!this.util.device.mobile || this.userState.demo) return;

    if (this.util.device.iOS) {
      if (this.util.device.iPhone) {
        await this.alert.messageWithImage('', '', '/assets/images/a2hs-iphone.png', 315, 508, 'Got it!');
      } else {
        await this.alert.messageWithImage('', '', '/assets/images/a2hs-ipad.png', 315, 508, 'Got it!');
      }
    } else {
      if (this.readyToInstall && this.deferredPrompt) {
        if (await this.alert.confirm('Install App',
          `Tap <b>Install</b> when prompted on the next screen.`, 'info', 'Show Prompt', 'Skip for Now')) {
          await (this.deferredPrompt as BeforeInstallPromptEvent)?.prompt();
        }
      } else {
        await this.alert.messageWithImage('', '', '/assets/images/a2hs-android.png', 315, 522, 'Got it!');
      }
    }
  }

  private checkIsInstalled(): boolean {
    if (this.manuallyInstalled || !this.util.device.mobile) {
      this.isInstalled = true;
    } else {
      this.isInstalled = (window.matchMedia('(display-mode: standalone)').matches) ||
        document.referrer.includes('android-app://');
    }
    return this.isInstalled;
  }

}
