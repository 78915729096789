import { Injectable } from '@angular/core';

import { DtService } from '@services/dt.service';

@Injectable({
  providedIn: 'root',
})
export class FmtService {

  private currencyFormatCache: { [key: string]: Intl.NumberFormat } = {};
  private percentFormatCache: { [key: string]: Intl.NumberFormat } = {};
  private numberFormatCache: { [key: string]: Intl.NumberFormat } = {};

  constructor(
    private dt: DtService,
  ) { }

  // NUMBER FORMATTING FUNCTIONS

  public format(format: string, value: number): string {
    const decimals = Number(format.slice(-1)) || 0;
    if (format.includes('currency')) return this.currency(value, decimals);
    if (format.includes('percent')) return this.percent(value, decimals);
    if (format.includes('number')) return this.number(value, decimals);
    console.error(`APP fmt.format: Unknown format ${format}`);
    return '';
  }

  public currency(number: number, decimals = 2): string {
    if (isNaN(number)) number = 0;
    return this.getCurrencyFormatter(decimals).format(number);
  }

  public percent(number: number, decimals = 0, showPlus = false): string {
    if (isNaN(number)) number = 0;
    return this.getPercentFormatter(decimals, showPlus).format(number);
  }

  public number(number: number, decimals = 0): string {
    if (isNaN(number)) number = 0;
    return this.getNumberFormatter(decimals).format(number);
  }

  // DATE FORMATTING FUNCTIONS

  public dateWithYear(date: string, days: number, short = false): string {
    return `${this.dt.format(date, days <= 365 ? (short ? 'MMM D' : 'MMMM D') : 'MMM D, YYYY')}`;
  }

  public dateRange(start: string, end = '', longMonth = false, showYear = true, numbersOnly = false): string {
    const MMMD = numbersOnly && end ? 'M/D' : longMonth ? 'MMMM D' : 'MMM D';
    const YYYY = !showYear ? '' : numbersOnly && end ? '/YY' : ', YYYY';

    const oneYear = start.substring(0, 4) === end.substring(0, 4) &&
      start === this.dt.startOf(start, 'year') && end === this.dt.endOf(end, 'year');
    if (oneYear) return `Jan to Dec ${this.dt.format(start, 'YYYY')}`;

    const oneMonth = this.dt.getMonth(start) === this.dt.getMonth(end) &&
      start === this.dt.startOf(start, 'month') && end === this.dt.endOf(end, 'month');
    if (oneMonth) return this.dt.format(start, `MMMM${showYear ? ' YYYY' : ''}`);

    if (start === end || !end) return this.dt.format(start, `${!numbersOnly ? 'ddd, ' : ''}${MMMD}${YYYY}`);

    const sameYear = start.substring(0, 4) === end.substring(0, 4);
    const sameMonth = start.substring(5, 7) === end.substring(5, 7);

    if (sameYear && sameMonth) {
      return `${this.dt.format(start, MMMD)}-${this.dt.format(end, `D${YYYY}`)}`;
    } else if (sameYear) {
      return `${this.dt.format(start, MMMD)}${numbersOnly ? ' - ' : ' to '}${this.dt.format(end, `${MMMD}${YYYY}`)}`;
    } else {
      return `${this.dt.format(start, `${MMMD}${YYYY}`)}${numbersOnly ? '-' : ' to '}${this.dt.format(end, `${MMMD}${YYYY}`)}`;
    }
  }

  public hour(hour: number, minute?: number, compress = false): string {
    const minuteStr = minute ? ':' + (minute < 10 ? '0' : '') + String(minute) : '';
    const amSuffix = `${minuteStr}${compress ? '' : ' '}am`;
    const pmSuffix = `${minuteStr}${compress ? '' : ' '}pm`;
    switch (true) {
      case (hour === 0 || hour === 24): return `12${amSuffix}`;
      case (hour < 12): return `${String(hour)}${amSuffix}`;
      case (hour === 12): return `12${pmSuffix}`;
      case (hour > 24): return `${String(hour - 24)}${amSuffix}`;
      default: return `${String(hour - 12)}${pmSuffix}`;
    }
  }

  public hourRange(startHour: number, startMinute: number, endHour: number, endMinute: number,
    compress = false): string {

    const am = compress ? 'am' : ' am';
    const pm = compress ? 'pm' : ' pm';

    const startHourStr = this.hour(startHour, startMinute, true);
    const endHourStr = this.hour(endHour, endMinute, true);
    if (startHourStr.includes(am) && endHourStr.includes(am)) {
      return `${startHourStr.replace(am, '')}-${endHourStr}`;
    } else if (startHourStr.includes(am) && endHourStr.includes(pm)) {
      return `${startHourStr.replace(pm, '')}-${endHourStr}`;
    } else {
      return `${startHourStr}${compress ? '' : ' '}-${compress ? '' : ' '}${endHourStr}`;
    }
  }

  // PACK FUNCTIONS

  public packBool(flag: boolean): string {
    return flag ? '1' : '0';
  }

  public packDate(date: string): string {
    // Remove YY from YYYY
    return this.dt.format(date, 'YYMMDD');
  }

  public packTimestamp(time?: number): string {
    if (!time) return '';
    return this.dt.formatYYMMDDHHmmss(time);
  }

  public packNum(number: number, decimals = 2, round = true): string {
    if (!number) return '0';

    // Truncate/round and then remove trailing zeroes to reduce storage space
    const adjusted = round ? number.toFixed(decimals) :
      Math.trunc(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
    const regExPattern = /(^\d+(?:\.\d*?[1-9](?=0|\b))?)\.?0*$/;
    const regExResult = regExPattern.exec(String(adjusted));
    return regExResult && regExResult.length >= 2 ? regExResult[1] : String(adjusted);
  }

  public packStr(str: string): string {
    // Encode pipes and trim spaces; convert value to string before replacement
    return str ? String(str).replace(/\|/g, '~~').trim() : '';
  }

  public packStrArray(arr: string[]): string {
    return !arr.length ? '' : arr.join('~');
  }

  // UNPACK FUNCTIONS

  public unpackBool(flag: string, defaultTo: boolean): boolean {
    return !flag ? defaultTo : flag === '1';
  }

  public unpackDate(date: string): string {
    return `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4, 6)}`;
  }

  public unpackTimestamp(date?: string): number {
    if (!date) return 0;
    return this.dt.newDate(`20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4, 6)}` +
      `T${date.substring(6, 8)}:${date.substring(8, 10)}:${date.substring(10, 12)}+00:00`).getTime();
  }

  public unpackNum(number: string): number {
    const num = +number;
    return isNaN(num) ? 0 : num;
  }

  public unpackStr(string: string): string {
    return string?.includes('~~') ? string.replace(/~~/g, '|') : string || '';
  }

  public unpackStrArray(str: string): string[] {
    return !str ? [] : str.split('~');
  }

  // SUPPORT FUNCTIONS

  private getNumberFormatter(decimals: number): Intl.NumberFormat {
    const key = `${decimals}`;
    if (!this.numberFormatCache[key]) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
      this.numberFormatCache[key] = formatter;
      return formatter;
    }
    return this.numberFormatCache[key];
  }

  private getCurrencyFormatter(decimals: number): Intl.NumberFormat {
    const key = `USD-${decimals}`;
    if (!this.currencyFormatCache[key]) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
      this.currencyFormatCache[key] = formatter;
    }
    return this.currencyFormatCache[key];
  }

  private getPercentFormatter(decimals: number, showPlus: boolean): Intl.NumberFormat {
    const key = `${decimals}|${showPlus}`;
    if (!this.percentFormatCache[key]) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
        signDisplay: showPlus ? 'exceptZero' : 'auto',
      });
      this.percentFormatCache[key] = formatter;
      return formatter;
    }
    return this.percentFormatCache[key];
  }
}
