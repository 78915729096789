import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { BehaviorSubject, catchError, filter, from, interval, of, Subject, switchMap, takeUntil } from 'rxjs';

import { SignoutService } from '@services/signout.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateService implements OnDestroy {
  public updateNow$ = new BehaviorSubject<boolean>(false);
  public updateAvailable = false;

  private destroy$ = new Subject<void>();

  constructor(
    private signout: SignoutService,
    private updates: SwUpdate,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initUpdates(): void {
    if (this.updates.isEnabled) {
      const every30Seconds$ = interval(30 * 1000);
      every30Seconds$
        .pipe(
          takeUntil(this.destroy$),
          takeUntil(this.signout.signout$),
          switchMap(() => from(this.updates.checkForUpdate()).pipe(
            catchError(error => {
              console.error('APP update.initUpdates: Failed to check for updates', error);
              return of(false);
            })
          )))
        .subscribe();

      this.updates.versionUpdates
        .pipe(
          takeUntil(this.destroy$),
          takeUntil(this.signout.signout$),
          filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
        )
        .subscribe(() => {
          this.updateAvailable = true;
          this.updateNow$.next(true);
        });
    }

    // If the user is in an unrecoverable state, force a reload
    this.updates.unrecoverable
      .pipe(takeUntil(this.destroy$), takeUntil(this.signout.signout$))
      .subscribe(() => {
        console.warn('APP update.initUpdates: Unrecoverable error; reloading app');
        window.location.reload();
      });
  }

  // Method to force update by unregistering and re-registering service worker
  public async forceUpdate(): Promise<void> {
    console.error('APP update.forceUpdate: Forcing update');
    try {
      // Unregister the existing service worker if one exists
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }

      // Clear the service worker cache
      if ('caches' in window) {
        const keys = await caches.keys();
        for (const key of keys) {
          await caches.delete(key);
        }
      }

      // Reload the app
      window.location.reload();
    } catch (error) {
      console.error('APP update.forceUpdate error:', error);
    }
  }

  public reload(): void {
    window.location.reload();
  }
}
