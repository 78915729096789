import { Injectable } from '@angular/core';

import { AfsService } from '@services/afs.service';

import { ClientSettings } from '@shared/settings.interface';

import { AppState } from '@state/app.state';

export interface InactiveConnection {
  locationID: string;
  locationName: string;
  connectionID: string;
  merchantID: string;
}

@Injectable({
  providedIn: 'root',
})
export class InactiveService {

  public connections: InactiveConnection[] = [];
  public tookAction = false;

  constructor(
    private afs: AfsService,
    private appState: AppState,
  ) { }

  public update(settings: ClientSettings): void {
    this.connections = [];

    for (const location of settings.locations ?? []) {
      // Ignore locations with at least one active Clover connection
      let validConnectionForLocation = false;
      for (const connection of location?.connections ?? []) {
        if (connection.sourceID === 'CL' && connection.active) validConnectionForLocation = true;
      }

      if (!validConnectionForLocation) {
        for (const connection of location?.connections ?? []) {
          if (connection.sourceID === 'CL' && !connection.active && connection.promptIfInactive !== false) {
            const inactiveConnection: InactiveConnection = {
              locationID: location.locationID,
              locationName: location.locationName ?? '',
              connectionID: connection.connectionID,
              merchantID: connection.id ?? '',
            };
            this.connections.push(inactiveConnection);
          }
        }
      }
    }
  }

  public async stopPrompting(settings: ClientSettings): Promise<void> {
    if (settings) {
      for (const location of settings.locations ?? []) {
        for (const connection of location.connections ?? []) {
          if (connection.connectionID === this.connections[0].connectionID) {
            connection.promptIfInactive = false;
          }
        }
      }
      await this.afs.setDocument<ClientSettings>('clients', this.appState.clientID, settings,
        { merge: false });
    }
  }
}
