import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { LsService } from '@services/ls.service';

import { NavigateToday, NavigateTrends } from '@shared/navigate.interface';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {
  constructor(
    private ls: LsService,
    private router: Router,
  ) { }

  public async home(params?: NavigationExtras): Promise<void> {
    const initialTab = this.ls.get('initialTab', 'last');
    let homeTab = initialTab === 'last' ? this.ls.get('lastTab', 'dashboard') : initialTab;
    if (homeTab === 'today') homeTab = 'dashboard';
    await this.route(homeTab, params);
  }

  public async today(navigateToday?: NavigateToday): Promise<void> {
    await this.route('dashboard', { queryParams: navigateToday ?? {} });

  }

  public async trends(navigateTrends?: NavigateTrends): Promise<void> {
    await this.route('trends', { queryParams: navigateTrends ?? {} });
  }

  private async route(page: string, params?: NavigationExtras): Promise<void> {
    await this.router.navigate([`app/${page}`], params ?? {});
  }

}
