import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { FilterPipe } from '@pipes/filter.pipe';
import { MarkdownLPipe } from '@pipes/markdown-l.pipe';
import { MarkdownMPipe } from '@pipes/markdown-m.pipe';
import { MarkdownSPipe } from '@pipes/markdown-s.pipe';
import { PasscodeKeysPipe } from '@pipes/passcode-keys.pipe';

@NgModule({
  declarations: [
    FilterPipe,
    MarkdownLPipe,
    MarkdownMPipe,
    MarkdownSPipe,
    PasscodeKeysPipe,
  ],
  imports: [IonicModule],
  exports: [
    FilterPipe,
    MarkdownLPipe,
    MarkdownMPipe,
    MarkdownSPipe,
    PasscodeKeysPipe,
  ],
})
export class PipesModule { }
