import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  public prod: boolean;
  public dev: boolean;
  public test: boolean;

  public isSafari: boolean;

  public name = 'test';
  public databaseURL: string;
  public projectId: string;
  public version: string;
  public shortVersion: string;
  public useSentry: boolean;

  public supportsMouse: boolean;
  public supportsTouch: boolean;

  constructor() {
    this.prod = environment.production && environment.firebase.projectId === 'manage-my-business';
    this.dev = environment.production && environment.firebase.projectId === 'manage-my-business-dev';
    this.test = !environment.production;

    if (this.prod) { this.name = 'prod'; }
    if (this.dev) { this.name = 'dev'; }
    if (this.test) { this.name = 'test'; }

    this.isSafari = navigator.userAgent.toLowerCase().includes('safari') &&
      !navigator.userAgent.toLowerCase().includes('chrome');

    this.databaseURL = environment.firebase.databaseURL;
    this.projectId = environment.firebase.projectId;
    this.version = environment.version;
    this.shortVersion = `${this.version.split('.')[0]}.${this.version.split('.')[1]}`;
    this.useSentry = this.prod || this.dev;

    this.supportsMouse = matchMedia('(pointer:fine)').matches;
    this.supportsTouch = 'ontouchstart' in window;
  }
}
