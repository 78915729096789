import { Injectable } from '@angular/core';

import { ColorsService } from '@services/colors.service';

import { ChannelSettings, channelSettings } from '@shared/channels.interface';
import { ClientSettings } from '@shared/settings.interface';
import { SourceSettings } from '@shared/sources.interface';

import { AppState } from '@state/app.state';
import { UserState } from '@state/user.state';

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {

  public settings: ChannelSettings[] = [];
  public mappedIDs: { [id: string]: string } = {};
  public allChannelIDs: string[] = [];

  constructor(
    private appState: AppState,
    private colors: ColorsService,
    private userState: UserState,
  ) {
    this.settings = channelSettings;
    this.allChannelIDs = this.settings.map(channel => channel.id);
  }

  public update(sources: SourceSettings[], clientSettings?: ClientSettings): void {
    for (const source of sources) {
      for (const channelID of source.channelIDs) {
        let mappedID = channelID;
        const otherChannelID = source.channelIDs.filter(id => id !== channelID)[0];
        let isOtherIDMapped = false;
        const location = clientSettings?.locations?.find(location =>
          location.locationID === this.appState.locationID);
        if (location) {
          const channel = location.channels?.find(channel => channel.channelID === channelID);
          if (channel?.mapChannel) {
            mappedID = source?.channelIDs?.find(sourceChannelID => sourceChannelID !== channelID) ?? channelID;
          }
          isOtherIDMapped = location.channels?.find(channel =>
            channel.channelID === otherChannelID)?.mapChannel ?? false;
        }

        // Only mapChannel if multiple channels exist for the sourceID
        const sourceID = this.settings.find(channel => channel.id === channelID)?.sourceID ?? '';
        if (this.settings.filter(channel => channel.sourceID === sourceID)?.length > 1) {
          this.mapChannel(channelID, mappedID, isOtherIDMapped);
        }
      }
    }

    // Create lookup table to speed up mappings
    for (const channel of this.settings) {
      this.mappedIDs[channel.id] = channel.mappedID;
    }

    const hiddenChannels = `|${this.userState.getPref('hiddenChannels', '') as string}|`;
    for (const channelID of this.allChannelIDs) {
      this.setVisible(channelID, !hiddenChannels.includes(`|${channelID}|`));
      if (!this.getSetting(channelID, 'color')) {
        const channel = clientSettings?.channels?.find(channel => channel.channelID === channelID);
        this.setColor(channelID, channel?.color);
      }
    }
  }

  private getChannel(channelID: string): ChannelSettings | undefined {
    return this.settings?.find(channel => channel.id === channelID);
  }

  private getChannelIndex(channelID: string): number {
    return this.settings?.findIndex(channel => channel.id === channelID);
  }

  public getSetting(channelID: string, setting: keyof ChannelSettings): string | string[] | boolean | undefined {
    if (this.settings && channelID && setting) {
      const channel = this.getChannel(channelID);
      return channel ? channel[setting] : undefined;
    }
    return '';
  }

  public mapChannel(channelID: string, mappedChannelID: string, isOtherIDMapped: boolean): void {
    const channel = this.getChannel(channelID);
    const mappedChannel = this.getChannel(mappedChannelID);

    if (channel && mappedChannel) {
      channel.mappedID = mappedChannelID;
      if (channelID !== mappedChannelID) {
        mappedChannel.name = mappedChannel.shortName;
        channel.name = channel.fullName;
      } else if (!isOtherIDMapped) {
        channel.name = channel.fullName;
      }
    }
  }

  public getDefaultMapChannel(channelID: string): boolean {
    return channelID === 'SQE' || channelID === 'UEP';
  }

  public isChannelMappedTo(channelID: string): boolean {
    return Object.values(this.mappedIDs).filter(id => id === channelID).length > 1;
  }

  public toggleVisible(channelID: string, visible?: boolean): void {
    this.setVisible(channelID, visible ?? !this.getSetting(channelID, 'visible'));

    const hiddenChannels: string[] = this.settings.reduce((acc: string[], channel) => {
      if (!channel.visible) {
        acc.push(channel.id);
      }
      return acc;
    }, []);

    this.userState.setPref('hiddenChannels', hiddenChannels.join('|'));
  }

  private setVisible(channelID: string, value: boolean): void {
    const channel = this.getChannel(channelID);
    if (channel) {
      channel.visible = value;
    }
  }

  public isVisible(channelID: string): boolean {
    return !this.settings || !channelID ? false : this.getChannel(channelID)?.visible ?? false;
  }

  public setColor(channelID: string, color?: string): string {
    const assignedColor = this.settings[this.getChannelIndex(channelID)]?.color;
    if (!color && assignedColor) return assignedColor;

    if (!color) color = this.colors.reserveColor();

    const channelColor = this.colors.channelColors.find(channel => channel.color === color);

    this.settings[this.getChannelIndex(channelID)].color = color;
    this.settings[this.getChannelIndex(channelID)].lightColor = channelColor?.lightColor ?? color;
    this.settings[this.getChannelIndex(channelID)].contrastColor = channelColor?.contrastColor ?? '#FFF';

    // Mark color as In Use
    if (channelColor) {
      channelColor.inUse = true;
    }

    return color;
  }

  public updateColorsInUse(remainingChannels: string[]) {
    for (const channel of this.settings) {
      if (!remainingChannels.includes(channel.id)) {
        this.releaseColor(channel.id, channel.color);
      }
    }
  }

  public releaseColor(channelID: string, color: string): void {
    const channelColor = this.colors.channelColors.find(channel => channel.color === color);

    this.settings[this.getChannelIndex(channelID)].color = '';
    this.settings[this.getChannelIndex(channelID)].contrastColor = '';

    // Mark color as not in use
    if (channelColor) {
      channelColor.inUse = false;
    }
  }

  public visibleChannelIDs(): string[] {
    let visibleChannels = this.settings.filter(channel => channel.visible);

    // Show all channels if none are visible (such as after deleting a visible channel)
    if (!visibleChannels.length) {
      visibleChannels = this.settings.map(channel => channel);
    }

    return visibleChannels.map(channel => channel.id);
  }

  public channelsForSourceID(channelIDs: string[]): ChannelSettings[] {
    const channels: ChannelSettings[] = [];
    for (const channelID of channelIDs ?? []) {
      const match = this.settings.find(channel => channel.id === channelID);
      if (match) channels.push(match);
    }
    return channels;
  }

  public demoOverride(channelID: string): void {
    const newChannel = this.settings.find(channel => channel.id === channelID);
    if (newChannel) {
      this.settings[0].name = newChannel.shortName;
      this.settings[0].fullName = newChannel.shortName;
      this.settings[0].shortName = newChannel.shortName;
      this.settings[0].icon = newChannel.icon;
    }
  }

  public getPOSIDs(): string[] {
    return this.settings
      .filter(channel => channel.types.includes('pos'))
      .map(channel => channel.id);
  }
}
