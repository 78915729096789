export const environment = {
  production: true,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  version: require('../../package.json').version,
  firebase: {
    apiKey: 'AIzaSyDj5YE2Mz46OG6axBJRZELqm1--wG5NXYw',
    authDomain: getAuthDomain(),
    databaseURL: getDatabaseURL(),
    projectId: 'manage-my-business',
    storageBucket: 'manage-my-business.appspot.com',
    messagingSenderId: '1017598264520',
    appId: '1:1017598264520:web:8af8aae51d0a1095',
    measurementId: 'G-J6B6LDJGN7',
  },
};

function getAuthDomain() {
  return window.location.origin.includes('managemybusiness') ?
    'secure.managemybusiness.app' : 'secure.getasprk.com';
}

function getDatabaseURL() {
  return window.location.origin.includes('managemybusiness') ?
    'https://secure.managemybusiness.app' : 'https://secure.getasprk.com';
}
