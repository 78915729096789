import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { NavigateService } from '@services/navigate.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalGuard implements CanActivate {
  constructor(
    private navigate: NavigateService,
    private router: Router,
  ) { }

  public async canActivate(): Promise<boolean> {
    if (this.router.url === '/' && !this.router.getCurrentNavigation()?.extras?.state?.internalRequest === true) {
      await this.navigate.home();
      return false;
    }
    return true;
  }

}
