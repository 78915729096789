import { Component } from '@angular/core';

import { DtService } from '@services/dt.service';
import { UtilitiesService } from '@services/utilities.service';

@Component({
  selector: 'app-calendar-icon',
  templateUrl: './calendar-icon.component.html',
  styleUrls: ['./calendar-icon.component.scss'],
})
export class CalendarIconComponent {
  public dayOfWeek = '';
  public date = '';

  constructor(
    public util: UtilitiesService,
    private dt: DtService,
  ) {
    this.dayOfWeek = this.dt.format(this.dt.now(), 'ddd');
    this.date = this.dt.format(this.dt.now(), 'D');
  }
}
